:root {
  --body-bg: #fff;
  --font-size: 1em;
  --error-color: #af3827;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--body-bg) !important;
  font-size: var(--font-size);
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
}

h1 {
  font-size: 26px;
}

.modal {
  z-index: 1041;
}

.modal-dialog {
  top: 40%;
  transform: translate(0, -50%) !important;
  z-index: 1041;
  max-width: 45em !important;
}

.modal-title {
  font-size: 1em !important;
  /* text-transform: lowercase; */
}

.modal-header,
.modal-body,
.modal-footer {
  border: none !important;
}

.modal-body {
  font-size: 0.9em !important;
  position: relative;
  left: -0.25em;
}

.btn {
  font-size: 0.9em !important;
  line-height: 1;
}

.custom-fileuploaded-label::after {
  content: 'Remove' !important;
  cursor: pointer;
}

.bg-info {
  background-color: #e0eeff !important;
}

.highlight-word {
  font-weight: bold;
  padding: 0;
  background-color: transparent;
}

#mydiv {
  display: none;
}

.justify-content-end {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.modal-content {
  border: none !important;
  padding: 40px 48px 40px 48px !important;
}

.modal-header,
.modal-footer {
  padding: 0.5em 0 !important;
}

.modal-body {
  padding: 1em 1em 0.5em 0 !important;
}

.modal-header {
  padding-top: 0 !important;
}

.modal-title {
  padding-left: 0 !important;
}

.cursor {
  cursor: pointer;
}

input:hover, textarea:hover, select:hover {
  border-color: #5C5C5C !important;
}
